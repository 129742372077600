.button {
    font-size: 22px;
    color: rgb(33, 53, 91);
    margin: 0px;
    padding: 0px;
    text-align: center;
    border: 2px solid rgb(33, 53, 91);
    border-radius: 50%;
    background: white;
    box-shadow: 0 0 2px rgb(0 0 0 / 15%);
    padding: 2.5px;
    height: max-content;
}

.buttonWrapper {
    padding: 6px;
    background-color: #d9f3f1;
    display: none;
    /* display: flex; */
    transition: all 400ms ease 0s;
    margin-top: 200px;
    text-align: center;
    border-radius: 0 100% 100% 0;
    height: fit-content;
    margin-right: -40px;
}

@media (max-width: 768px) {
    .buttonWrapper {
        display: none;
    }
}

div#sideBarAllMyOptions::-webkit-scrollbar {
    display: none;
}