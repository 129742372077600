.custom-select .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 100px;
    cursor: pointer;
}

.custom-select .ant-select-selection-search {
    display: flex;
    align-items: center;
    color: #00a19b
}

.custom-select .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    align-items: center;
    background: #00a19b;
    border-radius: 20px;
    padding: 20px;
}

.custom-select .ant-select-selection-placeholder {
    color: #fff;
    opacity: 1 !important;
    font-size: 20px;
}

.custom-select .ant-select-arrow {
    color: #fff;
    opacity: 1 !important;
    font-size: 20px;
    padding-right: 20px;
}

.custom-select .ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: pointer;
}